import React from 'react'
import { ActivityIndicator, TouchableOpacity, View, Text, TextInput, ScrollView, Image } from 'react-native'
import { style } from './style'
const packs = [{ montant_ht: 2, jetons: 20 }, { montant_ht: 3, jetons: 35 }, { montant_ht: 4, jetons: 50 }, { montant_ht: 5, jetons: 70 }]

export default class Buy extends React.Component {

    constructor(props) {
        super()
        this.state = {}
    }

    // params contient les éléments  {inscription_id,isTVA,customer_id,no_TVA,taux_tva,siren,credits,client:{nom,adresse},achats:[{display:{date,no_facture,montant_ttc},facture:{date,no,designation,montant_ht,montant_tva,mode_reglement,client:{nom,adresse,no_TVA,email}}}]}

    componentDidMount = () => {
        const { params } = this.props.route
        const tmp = {}
        tmp.isTVA = !params.no_TVA & true
        this.setState({ ...params, ...tmp, ready: true })
    }
    form = () => <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ backgroundColor: 'white' }}>

        {/* No de TVA */}
        <Text style={style.object}>La TVA n'est pas applicable si vous disposez d'un numéro intracommunautaire. Si tel est le cas, indiquez-le dans le champ ci-dessous.</Text>
        <TextInput style={style.TextInput} value={this.state.no_TVA} onChangeText={e => this.setState({ no_TVA: e })} placeholder="No de TVA" onFocus={() => this.setState({ err: [] })} onBlur={() => {
            if (!this.state.no_TVA.match(/[a-zA-Z]{2}[0-9]{11}/)) return this.setState({ err: ["Numéro de TVA invalide"], no_TVA: "", isTVA: true })
            if (this.state.no_TVA.substring(4) !== this.state.siren) return this.setState({ err: ["Le numéro de TVA n'est pas celui de l'entreprise"] })
            this.checktva()
        }} />
        <Text style={style.err}>{this.state.err}</Text>

        {/* Packs */}
        <View style={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap', alignSelf: 'center', margin: 10 }}>{packs.map((e, i) => <View key={i}>
            <TouchableOpacity style={[style.pack, { backgroundColor: this.state.montant_ht === e.montant_ht ? "darkslateblue" : "#816ef5" }]} onPress={() => this.setState(e)}><Text style={{ textAlign: 'center', fontSize: 12, color: 'white' }}>{`${e.jetons} jetons\n${e.montant_ht.toFixed(2)} €`}</Text></TouchableOpacity>
        </View>)}</View>

        {this.state.montant_ht ? <Text style={{ marginHorizontal: 20, textAlign: 'center', }}>Votre sélection : <Text style={{ fontWeight: 'bold', color: "#7b95b7" }}>{this.state.jetons} jetons</Text></Text> : <Text style={style.object}>Sélectionnez un pack</Text>}

        {this.state.montant_ht && (this.state.isTVA ? <Text style={{ marginHorizontal: 20, textAlign: 'center', }}>TVA : <Text style={{ fontWeight: 'bold', color: "#7b95b7" }}>{(this.state.montant_ht * 0.23).toFixed(2)}</Text></Text> : <Text style={style.object}>Aucune TVA</Text>)}

        {/* A payer */}
        {this.state.montant_ht && <Text style={style.titre}>A payer : <Text style={{ color: '#7b95b7' }}>{this.state.isTVA ? this.state.montant_ht * 1.23 : this.state.montant_ht} €</Text></Text>}

        {this.state.montant_ht && <TouchableOpacity onPress={() => this.props.navigation.navigate("Pay", this.data())} style={style.buybutton}><Text style={{ color: 'white' }}>Continuer</Text></TouchableOpacity>}

        {/* Conditions d'achat */}
        <View style={{ borderRadius: 10, borderColor: 'lightgray', borderWidth: 1, padding: 10, margin: 30 }}><Text style={{ color: 'gray', fontSize: 12 }}>Conditions d’achat : Chaque jeton est valable un an à compter du jour de l’achat. Il est uniquement lié à l’adresse email utilisée lors du paiement. Cette adresse doit être liée à un compte utilisateur de VTC-Resa. Un jeton n’est ni cessible ni transférable ni remboursable. Le coût d’achat d’un jeton peut évoluer dans le temps et peut dépendre de diverses offres promotionnelles. Il est débité à chaque utilisation d’un service. Le nombre de jeton débités dépend du service. Les jetons sont vendus par packs. Chaque pack est payable au comptant par carte bancaire. Il n’existe aucune récurrence d’achat. Les informations de la carte bancaire ne sont pas conservées. Le paiement est effectué par Stripe Inc. Ce prestataire est le seul à connaître le numéro de carte bancaire. VTC-Resa est un service proposé par Deews Limited. L’achat d’un service s’effectue hors TVA. La TVA n’est pas applicable si vous disposez d’un numéro intracommunautaire. Dans le cas contraire, la TVA est ajoutée à la facturation au taux en vigueur en Irlande et n’est pas déductible. VTC-Resa est une application dédiée aux professionnels du VTC uniquement. En cas de litige, seuls les tribunaux irlandais sont compétents. </Text></View>

        <Image source={require('./images/tri_icons.png')} style={{ width: 189, height: 55, alignSelf: 'center' }} />
    </ScrollView>

    data = () => {
        const tva_irl = 0.23
        const montant_tva = this.state.isTVA ? this.state.montant_ht * tva_irl : 0
        return {
            ...this.state,
            credits: this.state.jetons,
            montant_tva: montant_tva,
            montant_ttc: this.state.montant_ht + montant_tva,
            designation: "pack of " + this.state.jetons + " credits to be debited for the use of services on the VTC-Resa app available on Appstore and Google App"
        }

    }
    checktva = () => fetch('https://api.vtc-resa.com/paiement/checkvat.php?vat=' + this.state.no_TVA)
        .then(e => e.status !== 200 ? this.setState({ err: ["Numéro de TVA non reconnu"], no_TVA: "", isTVA: true }) : this.setState({ isTVA: false })).catch(e => alert(e))


    render = () => this.state.ready ? this.form() : <View style={style.container}><ActivityIndicator size="large" color="orange" /><Text style={{ textAlign: 'center' }}>{"Changement de la page\nVeuillez patienter..."}</Text></View>

}
