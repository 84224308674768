import React from 'react'
import { ActivityIndicator, TouchableOpacity, View, Text } from 'react-native'
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import { style } from './style'


export default class Pay extends React.Component {

    constructor() {
        super()
        this.state = {}
    }

    componentDidMount = async () => {

        this.stripe = await loadStripe
            // ('pk_test_51LzJEGCiX1YBL3amnbG5Rvo3EgYBiHqyjpicXCyQXM9BF9Ok24DjybXQ0yn64zwHTzakJDfN2GxCbjRqPkTVPuGT0073QI5G8b')
            ('pk_live_51LzJEGCiX1YBL3ambr6lXPq97KUUgH5vUMCuuadXgm1CeoJ6YfkVaHoF7CNo1vMXCpA4VfClnXhFW66HGkFj1jXP0042F08pIY')

        const { params } = this.props.route
        const body = new FormData()
        body.append('data', JSON.stringify({ montant: params.montant_ttc, customer_id: params.customer_id, email: params.email, name: params.client.nom, inscription_id: global.inscription_id }))
        await fetch('https://api.vtc-resa.com/paiement/paiementintent.php', { body: body, method: 'POST' })
            .then(e => e.status === 200 && e.json()
                .then(e => this.setState({ ...params, ...e, ready: true })))
            .catch(e => alert(e))
    }
    form = () =>
        <View style={style.container}>
            {/* <Text>{JSON.stringify(this.stripe)}</Text> */}
            <View style={{ backgroundColor: 'ghostwhite', padding: 15, borderRadius: 10, width: 350, alignSelf: 'center' }}>
                <Elements stripe={this.stripe} options={{ clientSecret: this.state.clientSecret }}>
                    <Text style={{ margin: 20 }}>Payer la somme de <Text style={{ color: 'darkslateblue' }}>{this.state.montant_ttc.toFixed(2)} € </Text></Text>
                    <ElementsConsumer>
                        {({ stripe, elements }) => (<CheckoutForm
                            data={this.state}
                            stripe={stripe}
                            elements={elements}
                            callback={() => this.props.navigation.replace("Home")} />)}
                    </ElementsConsumer>
                    <Text style={{ color: 'red', fontSize: 10, textAlign: 'center' }}>{this.state.payrollError}</Text>

                    <Text style={{ margin: 10, color: 'gray', fontSize: 10, fontStyle: 'italic', textAlign: 'center' }}>Votre carte bancaire est traitée par le terminal d'encaissement Stripe. Votre numéro de carte n'est pas conservé et nous n'en n'avons pas connaissance.</Text>
                </Elements>

            </View>
        </View>

    render = () => this.state.ready ? this.form() : <View style={style.container}><ActivityIndicator size="small" color="darkslateblue" /><Text style={{ textAlign: 'center' }}>{"Changement de la page\nVeuillez patienter..."}</Text></View>

}


class CheckoutForm extends React.Component {

    constructor() {
        super()
        this.state = {}
    }

    validate = async () => {


        this.setState({ loading: true })
        const { stripe, elements, data } = this.props;
        if (!stripe || !elements) return;
        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            redirect: "if_required",
        })

        if (error) this.setState({ err: error.message, loading: false })
        if (paymentIntent) {
            const body = new FormData()
            body.append('data', JSON.stringify(data))
            fetch('https://api.vtc-resa.com/paiement/paiementcomplete.php', { body: body, method: 'post' })
                .then(() => this.props.callback(true))
        }
    }

    render = () =>
        <View>
            <PaymentElement />
            {this.state.err && <Text style={style.err}>{this.state.err}</Text>}
            {this.state.loading ? <ActivityIndicator color="darkslateblue" size="small" /> : <TouchableOpacity onPress={() => this.validate()} style={style.buybutton}><Text style={{ color: 'white' }}>Payer</Text></TouchableOpacity>}
        </View>
}
