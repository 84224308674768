import React from "react";
import { ActivityIndicator, View } from "react-native";
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Buy from './buy'
import Pay from './pay'
import Home from './home'
import { style } from './style'



export default class App extends React.Component {

    constructor() {
        super()
        this.state = {}
    }

    componentDidMount = () => this.setState({ ready: true })


    render() {
        const Stack = createStackNavigator();
        const Layers = [
            { comp: Home, label: "Home", options: { title: "Mon compte" } },
            { comp: Buy, label: "Buy", options: { title: "Acheter" } },
            { comp: Pay, label: "Pay", options: { title: "Payer" } },
        ];

        return this.state.ready ?
            <NavigationContainer>
                <Stack.Navigator initialRouteName={"Home"}>
                    {Layers.map((e, i) => <Stack.Screen

                        name={e.label}
                        component={e.comp}
                        options={{ headerBackTitle: "Retour", ...e.options }}
                        key={i}
                    />)}
                </Stack.Navigator>
            </NavigationContainer > : <View style={style.container}><ActivityIndicator size="large" color="orange" /></View>
    }
}


