import React from 'react'

import { ActivityIndicator, TouchableOpacity, View, Text, ScrollView } from 'react-native'
import { style } from './style'

const params = new Proxy(new URLSearchParams(window.location.search), { get: (searchParams, prop) => searchParams.get(prop) });
const ref = params.ref


export default class Home extends React.Component {
    constructor() {
        super()
        this.state = {
            loadingMsg: !ref ? "Pour accéder à cette page, utilisez le lien présent sur les courriers électroniques envoyés par VTC-Resa, ou directement depuis votre application" : "Patientez, nous recherchons vos informations...",

        }
    }

    componentDidMount = async () => ref && await fetch("https://api.vtc-resa.com/paiement/?ref=" + ref).then(e => e.status !== 200 ? this.setState({ loadingMsg: "Compte inconnu" + e.status }) : e.json().then(e => this.setState({ ...e, isTVA: e.no_TVA ? false : true, ready: true }))).catch(e => this.setState({ loadingMsg: "Problème de connexion" }))

    // e.retourne paiement qui contient {customer_id,no_TVA,taux_tva,siren,credits,client:{nom,adresse},achats:[{display:{date,no_facture,montant_ttc},facture:{date,no,designation,montant_ht,montant_tva,mode_reglement,client:{nom,adresse,no_TVA,email}}}]}

    //  Dans e, on recherche le numéro de TVA et on renseigne isTVA en fonction de son existence (s'il n'existe pas, isTVA est true). les éléments de paiement sont maintenant en state.

    form = () => <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ backgroundColor: 'whitesmoke' }}>


        {/* Client */}
        <View style={{ margin: 20 }} />
        <Text style={style.text}>{this.state.client.nom}</Text>
        <Text style={style.text}>{this.state.client.adresse}</Text>

        {/* email */}
        <Text style={style.text}>Email : <Text style={{ color: "darkslateblue" }}>{this.state.email}</Text></Text>

        {/* TVA */}
        <View style={style.text}>{this.state.no_TVA ? <Text>No de TVA intracom. : <Text style={{ color: "darkslateblue", }}>{this.state.no_TVA}</Text></Text> : <Text></Text>}</View>

        {/* crédits */}
        <View >{this.state.credits ? <Text style={style.max}>Vous disposez de <Text style={[style.max, { color: "darkslateblue", fontWeight: 'bold' }]}>{this.state.credits}</Text> crédits</Text> : <Text style={style.max}>Vous n'avez aucun crédit</Text>}</View>

        {/* Acheter des jetons */}
        <TouchableOpacity style={style.buybutton} onPress={() => this.props.navigation.navigate("Buy", { inscription_id: ref, ...this.state })}><Text style={{ color: 'white' }}>Acheter des jetons</Text></TouchableOpacity>



        {/* Historique des achats */}
        <View style={{ margin: 20, alignSelf: 'center' }}>
            <Text style={{ margin: 10, textAlign: 'center', fontWeight: 'bold' }}>Historique des achats</Text>
            {
                this.state.achats && this.state.achats.length ?
                    <ScrollView style={{ maxHeight: 400 }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text style={style.txt}>Date</Text>
                            <Text style={style.txt}>Montant</Text>
                            <Text style={style.txt}>Facture</Text>
                        </View>
                        {this.state.achats
                            .map((e, i) => <View key={i} >
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderBottomColor: 'lightgray', borderBottomWidth: 1, padding: 10 }}>
                                    <Text style={style.txt}>{e.display.date}</Text>
                                    <Text style={style.txt}>{Number(e.display.montant_ttc).toFixed(2) + " €"}</Text>
                                    {!this.state["dwn" + i] ? <TouchableOpacity onPress={() => {
                                        this.setState({ ["dwn" + i]: true })
                                        const body = new FormData()
                                        body.append('data', JSON.stringify(e.facture))
                                        fetch('https://api.vtc-resa.com/paiement/duplicate_invoice.php', { body: body, method: 'POST' })
                                    }}><Text style={style.txt}>{"📩"}</Text></TouchableOpacity> : <Text style={style.txt}>{"📨"}</Text>}
                                </View>
                            </View>)}
                    </ScrollView> : <Text style={style.object}>Vous n'avez aucun achat.</Text>}
        </View>


        {/* Dépenses */}
        <Text style={{ margin: 30 }}>Vos 10 dernières dépenses  : <Text style={{ color: 'gray', }}>{this.state.depenses && this.state.depenses.reverse().slice(0, 10).join(" | ")}</Text> | {this.state.loading_csv ? <ActivityIndicator size="small" color="darkslateblue" /> : (this.state.csv ? <Text>Fichier envoyé</Text> : <TouchableOpacity onPress={() => this.getcreditused()}><Text style={{ color: 'steelblue' }}>Télécharger le fichier CSV de toutes vos dépenses.</Text></TouchableOpacity>)}</Text>




    </ScrollView>

    getcreditused = () => this.setState({ loading_csv: true }, () => {
        const body = new FormData()
        body.append("data", JSON.stringify({ email: this.state.email, entreprise: this.state.client.nom, depenses: this.state.depenses }))
        fetch('https://api.vtc-resa.com/paiement/sendcsv.php', { body: body, method: "post" })
            .then(e => e.status === 200 && this.setState({ csv: true, loading_csv: false }))

    })




    render = () => this.state.ready ? this.form() : <View style={style.container}>
        {ref && <ActivityIndicator size="large" color="darkslateblue" />}
        <Text style={{ textAlign: 'center' }}>{this.state.loadingMsg}</Text></View>

}

