import { StyleSheet } from 'react-native'

export const style = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white'
    },
    titre: {
        margin: 20,
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 'bold'
    },
    object: {
        fontStyle: 'italic',
        color: 'gray',
        textAlign: 'center',
        margin: 10
    },
    text: {
        borderRadius: 5,
        marginHorizontal: 30,
        alignSelf: 'center',
    },
    TextInput: {
        borderRadius: 5,
        margin: 5,
        padding: 10,
        backgroundColor: 'ghostwhite',
        alignSelf: 'center',
    },
    buybutton: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 12,
        },
        shadowOpacity: 0.58,
        shadowRadius: 16.00,

        elevation: 24,
        alignItems: 'center',
        borderRadius: 5,
        margin: 20,
        paddingHorizontal: 30,
        padding: 10,
        backgroundColor: 'darkslateblue',
        alignSelf: 'center'
    },
    paybutton: {
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 3, },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,
        elevation: 6,
        alignItems: 'center',
        borderRadius: 5,
        margin: 20,
        paddingHorizontal: 30,
        paddingVertical: 5,
        backgroundColor: 'orangered',
        alignSelf: 'center'
    },
    max: {
        fontWeight: 'bold',
        textAlign: 'center',
        marginVertical: 20,
        fontSize: 20
    },
    txt: {
        marginHorizontal: 30,
        textAlign: 'right'
    },
    err: {
        color: 'red',
        textAlign: 'center',
        margin: 20
    },
    pack: {
        paddingVertical: 30,
        paddingHorizontal: 7,
        margin: 2,
        borderRadius: 10,
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 3, },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,
        elevation: 6,
    }

})